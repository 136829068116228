import { AbstractControl, FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

/** Error when invalid control is dirty, touched, or submitted. */
export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

/**
 *
 * @param form AbstractControl
 * @returns Object with attribute isPasswordMatches boolean type
 */
export const passwordMatch = (form: AbstractControl): { isPasswordUnmatched: boolean } | null => {
  if (form.get("password").value !== form.get("confirmPassword").value) {
    return { isPasswordUnmatched: true };
  }
  return null;
};

/**
 *
 * @param form AbstractControl
 * @returns Object with attribute isPasswordMatches boolean type
 */
export const jsonValidator = (control: AbstractControl): { [key: string]: boolean } | null => {
  try {
    JSON.parse(control.value);
    return null;
  } catch (e) {
    return { pattern: true };
  }
};

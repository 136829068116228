import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { APIRoutes } from "@models/api-routes.models";
import { Observable } from "rxjs";
import { DatatableUser, DatatableUserData, UserPayload } from "@models/user.models";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  private readonly routes = new APIRoutes();

  public set storedUser(user: DatatableUserData) {
    localStorage.setItem("storedUser", user ? JSON.stringify(user) : "");
  }

  public get storedUser(): DatatableUserData {
    const user = localStorage.getItem("storedUser");
    return user ? (JSON.parse(user) as DatatableUserData) : null;
  }

  constructor(private readonly http: HttpClient) {}

  public list({
    limit,
    page,
    email,
  }: {
    limit: number;
    page: number;
    email: string;
  }): Observable<DatatableUser> {
    return this.http.get<DatatableUser>(this.routes.Users.All, {
      params: { limit: `${limit}`, page: `${page}`, email },
    });
  }

  public store(payload: UserPayload): Observable<void> {
    return this.http.post<void>(this.routes.Users.All, payload);
  }

  public update(id: number, payload: UserPayload): Observable<void> {
    return this.http.put<void>(this.routes.Users.Entity(id), payload);
  }

  public delete(id: number): Observable<void> {
    return this.http.delete<void>(this.routes.Users.Entity(id));
  }
}
